





























































import {defineComponent, ref} from '@vue/composition-api';
import {getCurrentUser} from "@/auth/identity";
import {isExtensionInstalled, openWebStorePage} from "@/util/extension";
import {Event, trackEvent} from "@/util/log";

export default defineComponent({
  name: 'Settings',
  setup() {
    trackEvent(Event.OPEN_SETTINGS_PAGE);

    const extensionInstalled = ref(false);
    isExtensionInstalled().then(b => extensionInstalled.value = b);
    return {user: getCurrentUser(), extensionInstalled, openWebStorePage};
  }
});
